<template>
  <div>
    <nav class="nav-side d-flex flex-column justify-content-between align-items-center">
      <div class="nav-name-wrapper vertical-text">
        <a href="#top" class="menu-name">
          <div class="neonText">Le Phoenix</div>
        </a>
      </div>
      <div class="nav-some_wrapper">
        <a href="https://www.instagram.com/lephoenix_annecy/" target="_blank" class="social-link">
          <img src="@/assets/instagram.svg" alt="Instagram logo">
        </a>
      </div>
    </nav>
    <main class="content flex-grow-1 d-flex flex-column" id="top">
      <nav class="navbar nav-top d-flex justify-content-around align-items-center p-0">
        <a class="nav-item nav-link" href="#menus">Menus</a>
        <div class="separator"></div>
        <a class="nav-item nav-link" href="#bar">Bar</a>
        <div class="separator"></div>
        <a class="nav-item nav-link" href="#salle">Salle</a>
        <div class="separator"></div>
        <a class="nav-item nav-link" href="#terrasse">Terrasse</a>
        <div class="separator"></div>
        <a class="nav-item nav-link" href="#evenements">Évenements</a>
        <div class="separator"></div>
        <a class="nav-item nav-link" href="#contact">Contact</a>
      </nav>
      <div class="main-content flex-grow-1 d-flex video-section">
        <div class="content-text d-flex" style="flex: 1;">
          <div class="neonText bigneon">Le Phoenix</div>
          <h2>Une expérience culinaire unique à Annecy</h2>
          <p>Situé au cœur de la vieille ville d'Annecy, Le Phoenix vous invite à découvrir une cuisine raffinée et innovante dans une ambiance florale et festive. Notre chef talentueux crée des plats qui allient tradition et modernité, utilisant les ingrédients les plus frais pour garantir une expérience gustative exceptionnelle.</p>
        </div>
        <div class="content-video lineleft">
          <div class="video-wrapper">
            <iframe src="https://player.vimeo.com/video/994710336?h=62fd40cff0&autoplay=1&loop=1&muted=1&background=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div class="main-content flex-grow-1 d-flex linetop linebottom">
        <div class="content-text d-flex align-items-center justify-content-center" style="flex: 1; padding:0; overflow:hidden; height:4rem;">
          <div class="content-image motif" style="width: 100%">
            <img src="@/assets/motif.svg" alt="Placeholder Image">
          </div>
        </div>
      </div>
      <div class="main-content flex-grow-1 d-flex linebottom" id="menus">
        <div class="content-image lineright">
          <img src="@/assets/photos/20240615-PhoenixSoiree-8.jpg" alt="Le Phoenix Menus">
        </div>
        <div class="content-text d-flex align-items-center justify-content-center orderchangemobile" style="flex: 2;">
          <MenusPage />
        </div>
      </div>
      <div class="main-content flex-grow-1 d-flex linebottom">
        <div class="content-text d-flex align-items-center justify-content-center" style="flex: 1; overflow:hidden;">
          <div class="scroll-text">
            Découvrez une cuisine singulière et soignée dans une ambiance florale et festive
          </div>
        </div>
      </div>
      <div class="main-content flex-grow-1 d-flex" id="bar">
        <div class="content-text d-flex align-items-center justify-content-center" style="flex: 1;">
          <div>
            <h1 class="display-1">Bar</h1>
            <p>Notre bar vous propose une sélection de cocktails créatifs, de vins fins et de boissons rafraîchissantes. Laissez-vous séduire par nos créations uniques et profitez d'un moment de détente dans une ambiance conviviale.</p>
          </div>
        </div>
        <div class="content-image lineleft orderchangemobile">
          <img src="@/assets/photos/20240615-PhoenixSoiree-3.jpg" alt="Le Phoenix Bar">
        </div>
      </div>
      <div class="main-content flex-grow-1 d-flex linetop" id="salle">
        <div class="content-image lineright">
          <img src="@/assets/photos/20240615-PhoenixSoiree-1.jpg" alt="Le Phoenix Salle">
        </div>
        <div class="content-text d-flex align-items-center justify-content-center" style="flex: 1;">
          <div>
            <h1 class="display-1">Salle</h1>
            <p>La salle du Phoenix vous accueille dans un cadre élégant et raffiné, parfait pour une soirée intime ou un dîner festif entre amis. Profitez d'un service impeccable et d'une ambiance chaleureuse pour un moment inoubliable.</p>
          </div>
        </div>
      </div>
      <div class="main-content flex-grow-1 d-flex linetop" id="terrasse">
        <div class="content-text d-flex align-items-center justify-content-center" style="flex: 2;">
          <div>
            <h1 class="display-1">Terrasse</h1>
            <p>La terrasse du Phoenix est l'endroit idéal pour profiter des beaux jours tout en dégustant nos délicieuses créations culinaires. Avec une vue imprenable sur la vieille ville d'Annecy, c'est l'endroit parfait pour un déjeuner en plein air ou un dîner sous les étoiles.</p>
          </div>
        </div>
        <div class="content-image lineleft orderchangemobile">
          <img src="@/assets/photos/20240615-PhoenixSoiree-6.jpg" alt="Le Phoenix Terrasse">
        </div>
      </div>
      <div class="main-content flex-grow-1 d-flex linetop" id="evenements">
        <div class="content-image lineright">
          <img src="@/assets/photos/20240615-PhoenixSoiree-20.jpg" alt="Le Phoenix Evenements">
        </div>
        <div class="content-text d-flex align-items-center justify-content-center" style="flex: 2;">
          <div>
            <h1 class="display-1">Évenements</h1>
            <p>Nous organisons divers événements tout au long de l'année, des soirées thématiques aux dégustations exclusives. Rejoignez-nous pour des moments mémorables, remplis de bonne humeur et de découvertes culinaires.</p>
          </div>
        </div>
      </div>
      <div class="main-content flex-grow-1 d-flex">
        <div class="content-text d-flex align-items-center justify-content-center" style="flex: 1; padding:0;">
          <iframe src="https://cdn.lightwidget.com/widgets/6293389b7c355c47ae8b7d471acb2092.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;"></iframe>
        </div>
      </div>
      <footer class="footer d-flex justify-content-between align-items-center p-3" id="contact">
        <div>
          <p>lephoenix.annecy@gmail.com</p>
          <p>04 50 02 63 99</p>
          <p>1 Quai Perrière</p>
          <p>74000 Annecy</p>
        </div>
        <div style="text-align: right;">
          <p>Restaurant 7j/7</p>
          <p>Service midi 12h à 14h15</p>
          <p>Service soir 19h30 à 22h15</p>
          <p>Bar 7j/7 de 9h à 1h30</p>
        </div>
      </footer>
      <div style="text-align: center; font-size: 0.8em;" class="notebas">
        <p><a href="www.lephoenixannecy.fr" style="color:black;">www.lephoenixannecy.fr</a> | © 2023-2024 Tous droits réservés. | Développé par <a href="www.thecompagnie.eu" target="_blank" style="color:black;">TheCompagnie</a></p>
      </div>
    </main>
  </div>
  <div class="zc-widget-config" data-restaurant="364568"></div>
</template>

<script>
import MenusPage from './MenusPage.vue';

export default {
  name: 'HomePage',
  components: {
    MenusPage
  }
};
</script>

<style scoped>
@font-face {
  font-family: 'Adventures Unlimited';
  src: local('Adventures Unlimited Script Bold'), local('Adventures-Unlimited-Script-Bold'),
      url('../assets/fonts/AdventuresUnlimited-ScriptBold.woff2') format('woff2'),
      url('../assets/fonts/AdventuresUnlimited-ScriptBold.woff') format('woff'),
      url('../assets/fonts/AdventuresUnlimited-ScriptBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0..900;1..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gotham:wght@100..900&display=swap');

body, html, #app {
  height: 100%;
  margin: 0;
  background-color: #fbecd2; /* Light background color */
  color: #1a1a1a; /* Dark text color */
  overflow-x: hidden; /* Prevent horizontal scroll */
  font-family: 'Gotham', sans-serif;
}

h1 {
  font-family: 'Fjalla One', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-family: 'Fjalla One', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.neonText {
  font-family: 'Adventures Unlimited', cursive;
}

.nav-side {
  border-right: 1px solid #1a1a1a; /* Dark border color */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 5.714rem;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  font-size: 3em;
}

.content {
  margin-left: 5.714rem;
  padding: 0;
  overflow-y: auto;
  min-height: 100vh; /* Ensure content takes full height */
  display: flex;
  flex-direction: column;
}

.nav-top {
  width: 100%;
  height: 4rem;
  border-bottom: 1px solid #1a1a1a; /* Dark border color */
  display: flex;
  align-items: center;
  padding: 0; /* Remove padding */
  font-weight: 400;
}

.nav-item {
  flex: 1;
  text-align: center;
}

.nav-item:hover {
  background-color: #1a1a1a; /* Dark background color */
  color: #fbecd2; /* Light text color */
}

.nav-link {
  font-family: 'Fjalla One', sans-serif;
  color: #1a1a1a; /* Dark text color */
  text-align: center;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  font-size: 1rem;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
}

.separator {
  width: 1px;
  height: 100%;
  background-color: #1a1a1a; /* Dark separator color */
}

.main-content {
  display: flex;
  flex-grow: 1;
}

.content-text {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-image, .content-video {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.content-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-video {
  max-height: 60vh;
}

.content-video iframe {
  pointer-events: none; /* Prevent interaction with the video */
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the container */
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 177.78%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lineright {
  border-right: 1px solid #1a1a1a; /* Dark border color */
}

.lineleft {
  border-left: 1px solid #1a1a1a; /* Dark border color */
}

.linetop {
  border-top: 1px solid #1a1a1a; /* Dark border color */
}

.linebottom {
  border-bottom: 1px solid #1a1a1a; /* Dark border color */
}

.footer {
  color: #1a1a1a; /* Dark text color */
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5em !important;
}

.footer p {
  margin: 0.5rem 0;
}

a {
  text-decoration: none !important;
}

.neonText {
  color: #1a1a1a; /* Dark text color */
  font-family: 'Adventures Unlimited', cursive;
  cursor: pointer;
  width: fit-content;
}

.neonText:hover {
  color: #fff;
  text-shadow:
    0 0 2px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #fff,
    0 0 82px #fff,
    0 0 92px #fff,
    0 0 102px #fff,
    0 0 151px #fff;
}

.nav-name-wrapper{
  margin-top: 0.5em;
}

.nav-some_wrapper{
  margin-bottom: 1em;
}

.bigneon{
  font-size: 10rem;
}

.scroll-text {
  text-transform: uppercase;
  display: inline-block;
  white-space: nowrap;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.subtitle {
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: 300;
  font-style: italic;
}

/* Responsive Styles */
@media (max-width: 1210px) {

  .vertical-text {
    font-size: 2.5em;
  }

  .bigneon{
    font-size: 5em;
  }

  .nav-top {
    height: 3rem;
  }

  h1{
    font-size: 3em;
  }

  h2{
    font-size: 1.6em;
  }

  .nav-link {
    font-size: 0.9rem;
  }

}

@media (max-width: 610px) {
  .orderchangemobile{
    order: -1;
  }

  .nav-side {
    width:3em;
  }

  .nav-top {
    flex-direction: column;
    height: auto;
  }

  .nav-link {
    padding: 0.5rem 0;
  }

  .separator {
    display: none;
  }

  .main-content {
    flex-direction: column;
  }

  .content-text {
    padding: rem;
  }

  .content-image img {
    border-top: 1px solid #1a1a1a; /* Dark border color */
    border-bottom: 1px solid #1a1a1a; /* Dark border color */
    border-left: 0; /* Dark border color */
    border-right: 0; /* Dark border color */
  }

  .lineright {
    border-right: 0; /* Dark border color */
  }

  .lineleft {
    border-left: 0; /* Dark border color */
  }

  .linetop {
    border-top: 0; /* Dark border color */
  }

  .linebottom {
    border-bottom: 0; /* Dark border color */
  }

  .content{
    margin-left: 3em;
  }

  .bigneon {
    font-size: 5rem;
  }

  .lineleft{
    border: none;
  }

  .lineright{
    border: none;
  }

  .linetop{
    border: none;
  }

  .footer {
    flex-direction: column;
    text-align: center;
  }

  .motif{
    display:none;
  }

  .notebas {
    margin-bottom: 5rem;
  }
}
</style>
